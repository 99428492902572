import Login from '@/modules/login/Login.vue';
import Footer from '@/modules/footer/Footer.vue';
import Header from '@/modules/header/Header.vue';
import store from '@/store';
import { INITIALIZE_FROM_COOKIES_AC } from '@/store/auth/actions.type';

const beforeEnterLogin = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      return next('/home');
    }
    return next();
  }
  return next('/home');
};

export default {
  path: '/login',
  name: 'login',
  meta: {
    title: 'Login | Proxpect',
  },
  components: {
    default: Login,
    header: Header,
    footer: Footer,
  },
  beforeEnter: beforeEnterLogin,
};
