<template>
  <div class="ml-5 mb-5 body-2" v-if="categoriaCompleta">
    <router-link :to="`/q/d/${categoriaCompleta.grupo.departamento.id}`">
      {{ categoriaCompleta.grupo.departamento.descricao }}
    </router-link>
    >
    <router-link :to="`/q/g/${categoriaCompleta.grupo.id}`">
      {{ categoriaCompleta.grupo.descricao }}
    </router-link>
    >
    <router-link :to="`/q/c/${categoriaCompleta.id}`">
      {{ categoriaCompleta.descricao }}
    </router-link>
  </div>
</template>

<script>
import CategoriaService from '@/api/categoria-service';

export default {
  props: {
    categoria: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categoriaCompleta: null,
    };
  },
  methods: {
    getCategoria() {
      CategoriaService.getCategoria(this.categoria.id)
        .then(({ data }) => {
          this.categoriaCompleta = data;
        });
    },
  },
  created() {
    this.getCategoria();
  },
};
</script>
