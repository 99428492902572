function push(eventName, customParams) {
  try {
    if (!window.dataLayer) {
      return;
    }
    window.dataLayer.push({ event: eventName, ...customParams });
  } catch (e) {
    // apenas loga o erro
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function buildPush(eventName, buildCustomParams) {
  try {
    if (!window.dataLayer) {
      return;
    }
    const customParams = buildCustomParams();
    window.dataLayer.push({ event: eventName, ...customParams });
  } catch (e) {
    // apenas loga o erro
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default {
  push,
  buildPush,
};
