export default {
  itens(state) {
    return state.itens;
  },
  loading(state) {
    return state.loading;
  },
  loadingAdd(state) {
    return state.loadingAdd;
  },
};
