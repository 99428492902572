<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="d-flex align-center">
        <div>
          <slot name="before"/>
          <span class="headline font-weight-bold">{{ title }}</span>
          <slot name="after"/>
        </div>
      </v-col>
      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
        <v-breadcrumbs class="justify-end"
                       :items="breadcrumbs" divider=">"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['breadcrumbs', 'title'],
};
</script>
