export default {
  sortOptions(state) {
    return state.sortOptions;
  },
  paramName(state) {
    return state.paramName;
  },
  defaultSort(state) {
    return state.sortOptions[0];
  },
  sortOption: state => field => state.sortOptions.find(option => option.field === field),
};
