<template>
  <div id="oferta-avaliacao-list" class="mt-8 ml-5" v-if="avaliacoes.content.length">
    <div class="headline font-weight-bold-2">Avaliações</div>
    <v-skeleton-loader
      :loading="loading"
      type="list-item-two-line@3"
    >
      <template v-for="avaliacao in avaliacoes.content">
        <px-oferta-avaliacao-list-item :avaliacao="avaliacao"
                                       class="mt-10"
                                       :key="avaliacao.id"/>
      </template>
      <v-skeleton-loader
        :loading="loadingMore"
        type="list-item-two-line@3"
      >
        <v-row class="mt-5 mr-5">
          <v-col cols="12" class="text-right">
            <a class="text--primary decoration-link"
               v-if="showShowMore"
               @click="loadMoreItems">
              Ver mais
            </a>
          </v-col>
        </v-row>
      </v-skeleton-loader>
    </v-skeleton-loader>
  </div>
</template>

<script>
import OfertaAvaliacaoListItem from './OfertaAvaliacaoListItem.vue';
import OfertaAvaliacaoService from './oferta-avaliacao-service';

const LIMIT = 3;

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingMore: false,
      offset: 0,
      avaliacoes: {
        content: [],
        total: 0,
      },
    };
  },
  computed: {
    showShowMore() {
      return this.offset < this.avaliacoes.total;
    },
  },
  methods: {
    loadMoreItems() {
      this.loadingMore = true;
      this.getAvaliacoesList({
        limit: LIMIT,
        offset: this.offset,
      })
        .then(({ data }) => {
          this.avaliacoes.content = this.avaliacoes.content.concat(data.content);
          this.offset += LIMIT;
        })
        .finally(() => {
          this.loadingMore = false;
        });
    },
    getAvaliacoesList(params) {
      const queryParams = {
        query: 'order by dataResposta desc',
        ...params,
      };
      return OfertaAvaliacaoService.getAvaliacaoOfertaList(this.oferta.id, queryParams);
    },
    getInitialAvaliacoes() {
      this.loading = true;
      this.getAvaliacoesList({
        limit: LIMIT,
        offset: this.offset,
      })
        .then(({ data }) => {
          this.avaliacoes = data;
          this.offset += LIMIT;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getInitialAvaliacoes();
  },
  components: {
    pxOfertaAvaliacaoListItem: OfertaAvaliacaoListItem,
  },
};
</script>
