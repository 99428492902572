<template>
  <v-card
    class="elevation-0"
  >
    <v-card-text class="text--primary">
      <px-oferta-preco-header :oferta="oferta" :is-partial-view="isPartialView"/>
      <px-oferta-info-fornecedor :oferta="oferta"/>
      <px-oferta-tag-info :oferta="oferta"/>
      <px-oferta-avaliacao :oferta="oferta"/>
      <px-oferta-info-precos class="mt-10"
                             :oferta="oferta"
                             :variacao="variacao"
                             :hide-price="hidePrice"/>
      <div>
        <template v-if="variacao.disponivel">
          <px-oferta-info-pedido-minimo :oferta="oferta"
                                        :quantidade="quantidade"
                                        :variacao="variacao"/>
          <px-oferta-formas-pagamento :oferta="oferta" />
        </template>
        <px-oferta-grades ref="grades"
                          v-if="oferta.possuiVariacao"
                          @selected="onSelectAllGrades"
                          :oferta="oferta"/>
        <template v-if="variacao.disponivel">
          <px-oferta-info-fretes
            v-if="!hidePrice"
            class="mt-8"
            :comprador="compradorSelecionado"
            :variacao="variacao"
            :oferta="oferta"/>
          <px-oferta-comparacao-select v-if="!isPartialView && !hidePrice"
                                       class="mt-8"
                                       :oferta-param="oferta"/>
          <px-alerta-preco-btn v-if="!isPartialView && !hidePrice"
                               class="mt-3"
                               @click="onClickAlert"/>
        </template>
      </div>

      <template v-if="hidePrice">
        <px-oferta-info-solicitar-cotacao :oferta="oferta"
                                          :variacao="variacao"/>
      </template>
      <template v-else>
        <px-oferta-info-add-carrinho @comprador="changeComprador"
                                     @quantidade="changeQuantidade"
                                     @addedcarrinho="addedCarrinho"
                                     :validate-function="validate"
                                     :comprador-default="compradorDefault"
                                     :quantidade-default="quantidadeDefault"
                                     :oferta="oferta"
                                     :variacao="variacao"/>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { find } from 'lodash';
import { mapGetters } from 'vuex';
import { VisualizacaoPreco } from 'px-business-components';
import OfertaInfoFornecedor from './OfertaInfoFornecedor.vue';
import OfertaInfoPrecos from './OfertaInfoPrecos.vue';
import OfertaInfoFretes from './OfertaInfoFretes.vue';
import OfertaInfoPedidoMinimo from './OfertaInfoPedidoMinimo.vue';
import OfertaInfoAddCarrinho from './OfertaInfoAddCarrinho.vue';
import OfertaPrecoHeader from './OfertaPrecoHeader.vue';
import OfertaGrades from './OfertaGrades.vue';
import OfertaFormasPagamento from './OfertaFormasPagamento.vue';
import OfertaAvaliacao from './avaliacao/OfertaAvaliacao.vue';
import PxOfertaComparacaoSelect from '../../compare/PxOfertaComparacaoSelect.vue';
import OfertaTagInfo from './OfertaTagInfo.vue';
import PxAlertaPrecoBtn from './components/PxAlertaPrecoBtn.vue';
import OfertaInfoSolicitarCotacao from './OfertaInfoSolicitarCotacao.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    quantidadeDefault: {
      type: [String, Number],
      default: 0,
    },
    compradorDefault: {
      type: Object,
      default: null,
    },
    isPartialView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: false,
      quantidade: null,
      compradorSelecionado: null,
      variacao: null,
      variacoes: [],
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    hidePrice() {
      return this.oferta.categoria.visualizacaoPreco === VisualizacaoPreco.keys.OCULTO.key
        || (this.oferta.categoria.visualizacaoPreco
          === VisualizacaoPreco.keys.EXIBIR_USUARIO_LOGADO.key && !this.isLogged);
    },
  },
  methods: {
    validate() {
      if (!this.$refs.grades) {
        return false;
      }
      return this.$refs.grades.validate();
    },
    changeComprador(comprador) {
      this.compradorSelecionado = comprador;
      this.$emit('comprador', comprador);
    },
    addedCarrinho() {
      this.$emit('addedcarrinho');
    },
    changeQuantidade(quantidade) {
      this.quantidade = quantidade;
    },
    onSelectAllGrades(grades) {
      const variacaoBusca = {};
      grades.forEach((g) => {
        variacaoBusca[g.grade.id] = g.id;
      });
      const variacao = find(this.variacoes, variacaoBusca);
      if (variacao) {
        this.variacao = variacao;
      }
    },
    buildNormalizedVariacao(variacao) {
      const result = { ...variacao };
      result.variacoes.forEach((v) => {
        result[v.grade.id] = v.id;
      });
      return result;
    },
    normalizeOfertasVariacoes() {
      this.variacoes = this.oferta.variacoes.map(this.buildNormalizedVariacao);
    },
    onClickAlert() {
      this.$emit('clickalert');
    },
  },
  created() {
    this.variacao = this.oferta;
    if (this.oferta.possuiVariacao) {
      this.normalizeOfertasVariacoes();
    }
  },
  components: {
    pxOfertaInfoFornecedor: OfertaInfoFornecedor,
    pxOfertaInfoPrecos: OfertaInfoPrecos,
    pxOfertaInfoFretes: OfertaInfoFretes,
    pxOfertaInfoPedidoMinimo: OfertaInfoPedidoMinimo,
    pxOfertaInfoAddCarrinho: OfertaInfoAddCarrinho,
    pxOfertaPrecoHeader: OfertaPrecoHeader,
    pxOfertaGrades: OfertaGrades,
    pxOfertaFormasPagamento: OfertaFormasPagamento,
    pxOfertaAvaliacao: OfertaAvaliacao,
    PxOfertaComparacaoSelect,
    PxAlertaPrecoBtn,
    pxOfertaTagInfo: OfertaTagInfo,
    pxOfertaInfoSolicitarCotacao: OfertaInfoSolicitarCotacao,
  },
};
</script>
