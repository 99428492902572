<template>
  <px-lateral-dialog v-model="dialog" width="1000">
    <template v-slot:activator="{ on }">
      <slot name="trigger" v-bind:on="on">
        <v-btn small
               text
               class="normal-btn-text"
               color="primary"
               v-on="on">
          <v-icon small>
            mdi-redo-variant
          </v-icon>
          {{ btnDescription }}
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
    </template>
    <template>
      <div v-if="dialog">
        <v-btn icon class="dialog-close-btn" @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <px-oferta is-partial-view
                   @addedcarrinho="onAddCarrinho"
                   :comprador-default="comprador"
                   :quantidade-default="quantidade"
                   :id-oferta="idOferta"/>
      </div>
    </template>
  </px-lateral-dialog>
</template>

<script>
import Oferta from './Oferta.vue';

export default {
  props: {
    idOferta: [String, Number],
    btnDescription: {
      type: String,
      default: 'Comprar novamente',
    },
    quantidade: {
      type: [String, Number],
    },
    comprador: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    onAddCarrinho() {
      this.dialog = false;
    },
    onClose() {
      this.dialog = false;
    },
  },
  components: {
    pxOferta: Oferta,
  },
};
</script>

<style scoped>
.dialog-close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
;
}
</style>
