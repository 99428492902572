<template>
  <div class="body-2">
    <slot v-if="!isSelected" name="add" v-bind:on="{ click: onSelect }">
      <a @click="onSelect">
        <v-icon color="primary">mdi-format-list-bulleted-square</v-icon>
        Adicionar à comparação
      </a>
    </slot>
    <slot v-if="isSelected" name="remove" v-bind:on="{ click: onRemove }">
      <a @click="onRemove">
        <v-icon color="primary">mdi-close</v-icon>
        Remover da comparação
      </a>
    </slot>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ADD_OFERTA_ACT, REMOVE_OFERTA_ACT } from '@/store/compare/actions.type';

export default {
  props: {
    ofertaParam: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('compare', ['ofertas', 'oferta']),
    isSelected() {
      return Boolean(this.oferta(this.ofertaParam.id));
    },
  },
  methods: {
    ...mapActions('compare', {
      addOferta: ADD_OFERTA_ACT,
      removeOferta: REMOVE_OFERTA_ACT,
    }),
    onSelect() {
      this.addOferta({
        id: this.ofertaParam.id,
        titulo: this.ofertaParam.titulo,
        foto: this.ofertaParam.foto,
      });
    },
    onRemove() {
      this.removeOferta({
        id: this.ofertaParam.id,
      });
    },
  },
};
</script>
