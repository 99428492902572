export default {
  user(state) {
    return state.user;
  },
  loading(state) {
    return state.loading;
  },
  hasModuleGerencia(state) {
    if (!state.user) {
      return false;
    }
    return state.user.roles.map(r => r.name).includes('ROLE_COMPRADOR');
  },
  hasModuleCompraConjunta(state) {
    if (!state.user) {
      return false;
    }
    return state.user.roles.map(r => r.name).includes('ROLE_COMPRA_CONJUNTA');
  },
  hasModuleGestorFrete(state) {
    if (!state.user) {
      return false;
    }
    return state.user.roles.map(r => r.name).includes('ROLE_GESTOR_FRETE');
  },
  hasModuleGerenciaOrCompraConjunta(state, getters) {
    if (!state.user) {
      return false;
    }
    return getters.hasModuleGerencia || getters.hasModuleCompraConjunta;
  },
};
