import axios from '../marketplace/axios-marketplace';

const getDepartamentos = () => axios.get('/departamentos/todos');
const getDepartamentosFixos = () => axios.get('/departamentos/fixos');
const getDepartamentosOnly = params => axios.get('/departamentos', { params });

export default {
  getDepartamentos,
  getDepartamentosFixos,
  getDepartamentosOnly,
};
