<template>
  <div>
    <template v-if="hidePrice">
      <span class="display-1 font-weight-bold-2 text--primary--color">
        Preço sob consulta
      </span>
    </template>
    <template v-else>
      <div class="body-1 mt-5">
        <template v-if="oferta.promocaoAtiva">
          <px-data-promocao v-if="oferta.promocao.dataLimite"
                            class="mb-8"
                            :date-limit="oferta.promocao.dataLimite"/>
          <span class="title text--secondary font-weight-light text-line-through">
          De {{ variacao.precoAVista | currency }}
        </span>
        </template>
      </div>
      <div class="text--primary mt-1">
      <span class="display-2 font-weight-bold-2 text--primary--color">
        por {{ precoAVista | currency }}
      </span>
        <span class="text--secondary headline">à vista</span>
      </div>
      <div class="text--secondary body-1 mt-1">
        ou {{ precoAPrazo | currency }}
        a prazo
      </div>
      <px-oferta-impostos :oferta="oferta"
                          :variacao="variacao"
                          class="mt-2" />
    </template>
  </div>
</template>

<script>
import PxOfertaImpostos from './components/PxOfertaImpostos.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    variacao: {
      type: Object,
      required: true,
    },
    hidePrice: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    precoAVista() {
      return this.oferta.promocaoAtiva
        ? this.variacao.precoPromocionalAVista : this.variacao.precoAVista;
    },
    precoAPrazo() {
      return this.oferta.promocaoAtiva
        ? this.variacao.precoPromocionalAPrazo : this.variacao.precoAPrazo;
    },
  },
  components: {
    PxOfertaImpostos,
  },
};
</script>
