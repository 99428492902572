import AlterarSenha from '@/modules/login/alterar-senha/AlterarSenha.vue';
import Header from '@/modules/header/Header.vue';
import Footer from '@/modules/footer/Footer.vue';

export default {
  path: '/redefinir-senha/:chave',
  name: 'redefinir-senha',
  components: {
    header: Header,
    footer: Footer,
    default: AlterarSenha,
  },
};
