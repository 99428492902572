<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-else>
      <px-oferta-alerta-preco-criado v-if="alerta && !updating"
                                     :oferta="oferta"
                                     :alerta="alerta"
                                     @update="setUpdate"/>
      <px-oferta-alerta-preco-criar v-if="!alerta || updating"
                                    :oferta="oferta"
                                    :alerta-prop="alerta"
                                    @change="onChangeAlerta"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OfertaAlertaService from '@/modules/ofertas/oferta/alerta-preco/oferta-alerta-service';
import OfertaAlertaPrecoCriar from './OfertaAlertaPrecoCriar.vue';
import OfertaAlertaPrecoCriado from './OfertaAlertaPrecoCriado.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alerta: null,
      loading: false,
      updating: false,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    onChangeAlerta(alerta) {
      this.alerta = alerta;
      this.updating = false;
    },
    setUpdate() {
      this.updating = true;
    },
    getAlerta() {
      this.loading = true;
      OfertaAlertaService.getAlerta(this.oferta.id)
        .then(({ data }) => {
          if (data) {
            this.alerta = data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.isLogged) {
      this.getAlerta();
    }
  },
  components: {
    pxOfertaAlertaPrecoCriado: OfertaAlertaPrecoCriado,
    pxOfertaAlertaPrecoCriar: OfertaAlertaPrecoCriar,
  },
};
</script>
