<template>
  <div>
    <v-row>
      <v-col cols="12" class="menu-itens">
        <px-departamentos-menu v-if="!isMobile" class="d-inline-block mr-5"/>
        <px-departamentos-menu-mobile v-else class="d-inline-block mr-5"/>
        <router-link to="/q/p/promo" class="mx-3 menu-link text--primary font-weight-bold">
          <span class="normal-btn-text">&nbsp;Promoções</span>
        </router-link>
        <template v-if="!isSm && !loading && departamentos.length">
          <router-link class="mx-5 menu-link text--primary font-weight-bold"
                 v-for="departamento in departamentosFixos"
                 :key="departamento.id" :to="`/q/d/${departamento.id}`">
            <span class="normal-btn-text">{{ departamento.descricao }}</span>
          </router-link>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DepartamentosMenu from '@/modules/departamentos-menu/DepartamentosMenu.vue';
import { mapGetters } from 'vuex';
import gridMixin from '@/mixins/grid-mixin';
import MenuService from '../departamentos-menu/menu-service';
import DepartamentosMenuMobile from '../departamentos-menu/DepartamentosMenuMobile.vue';

export default {
  mixins: [gridMixin],
  data() {
    return {
      loading: false,
      departamentos: [],
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    departamentosFixos() {
      if (this.isMd) {
        return this.departamentos.slice(0, 5);
      }
      return this.departamentos;
    },
    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isMd() {
      return this.$vuetify.breakpoint.mdOnly;
    },
  },
  methods: {
    getDepartamentos() {
      this.loading = true;
      MenuService.getDepartamentosFixos()
        .then(({ data }) => {
          this.departamentos = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getDepartamentos();
  },
  components: {
    pxDepartamentosMenu: DepartamentosMenu,
    pxDepartamentosMenuMobile: DepartamentosMenuMobile,
  },
};
</script>

<style scoped>
.menu-itens {
  white-space: nowrap;
  width: 1280px;
  overflow-x: hidden;
}
</style>
