<template>
  <v-chip v-if="showChip"
          label
          small
          class="rounded-tag-small"
          outlined
          :text-color="textColor"
          color="#EBBF0F">
    IPI não incluso
  </v-chip>
</template>

<script>
const TAX_COLOR = '#EBBF0F';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showChip() {
      return this.oferta.ipi && this.oferta.ipi.destacar;
    },
    textColor() {
      return TAX_COLOR;
    },
  },
};
</script>
