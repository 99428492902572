<template>
  <span>
    <template v-if="isPrazoRespondido">
      {{ value | currency }}
    </template>
    <template v-else>
      -
    </template>
  </span>
</template>

<script>
import { SituacaoRespostaPrazo } from 'px-business-components';

export default {
  props: {
    value: [String, Number],
    situacao: String,
  },
  computed: {
    isPrazoRespondido() {
      return this.situacao === SituacaoRespostaPrazo.keys.RESPONDIDA.key;
    },
  },
};
</script>
