<template>
  <nav>
    <div class="header-container d-flex flex-column align-center justify-center">
      <div class="d-flex mt-3 mb-3 px-5 align-center justify-space-between full-width">
        <div class="d-flex">
          <router-link to="/home">
            <img class="header-logo-small" src="../../assets/logo.svg"
                 alt="Logo da Proxpect"/>
          </router-link>
        </div>
        <div class="d-flex align-center">
          <px-header-user :is-logged="isLogged" class="mr-3"/>
          <px-notificacoes v-if="isLogged"
                           width="350"
                           icon="mdi-bell-outline"
                           badge-color="primary"/>
          <px-carrinho-button/>
        </div>
      </div>
      <div class="full-width">
        <px-search-bar height="50px" class="px-5"/>
      </div>
      <div class="header-options mt-3">
        <px-menu-ofertas/>
      </div>
    </div>
  </nav>
</template>

<script>
import PxCarrinhoButton from '@/modules/carrinho/PxCarrinhoButton.vue';
import { mapGetters } from 'vuex';
import SearchBar from '../search-bar/SearchBar.vue';
import HeaderUser from './HeaderUser.vue';
import MenuOfertas from '../menu-ofertas/MenuOfertas.vue';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isLogged']),
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isXs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    goToHome() {
      if (this.$router.currentRoute.name === 'public.home') {
        return;
      }
      this.$router.push({ name: 'public.home' });
    },
  },
  components: {
    PxCarrinhoButton,
    pxSearchBar: SearchBar,
    pxHeaderUser: HeaderUser,
    pxMenuOfertas: MenuOfertas,
  },
};
</script>

<style scoped>
.header-container {
  top: 0;
  left: 0;
  width: 100%;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  opacity: 1;
}

@media (min-width: 960px) {
  .header-container {
    height: 230px;
  }
}

.header-logo-small {
  width: 130px;
}

.header-options {
  width: 100%;
  max-width: 1280px;
}
</style>
