<template>
  <div>
    <div>
      Qualquer valor
      <px-line-indicator/>
      Boleto ou Pix à vista
    </div>
    <div v-for="formaPagamento in formasPagamento" :key="formaPagamento.id" class="mt-1">
      A partir de <b>{{ formaPagamento.valorMinimo | currency }}</b>
      <px-line-indicator/>
      {{ formaPagamento.prazoPagamento.descricao }} dias no boleto
    </div>
    <div class="mt-1 text--primary--color" v-if="showDetailsMessage">
      A forma de pagamento se aplica à compra com o fornecedor e não somente a este produto.
      <router-link to="/faq">
        Entenda mais.
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    formasPagamento: Array,
    showDetailsMessage: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
