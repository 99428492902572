function getBasePath(to) {
  let { fullPath } = to;
  Object.keys(to.params)
    .forEach((key) => {
      fullPath = fullPath.replace(to.params[key], key);
    });
  return fullPath;
}

export default function (to) {
  const basePath = getBasePath(to);
  window.ga('send', 'pageview', basePath);
}
