<template>
  <div class="oferta-img-content">
    <px-oferta-foto max-height="500"
                    max-width="500"
                    align="center"
                    :alt="oferta.titulo"
                    img-class="elevation-3"
                    :foto="oferta.foto">
      <template v-slot:image>
        <px-selo-desconto v-if="oferta.promocaoAtiva"
                          class="selo-desconto-oferta"
                          :oferta="oferta"/>
      </template>
    </px-oferta-foto>
  </div>
</template>

<script>
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.selo-desconto-oferta {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 1;
}

.oferta-img-content {
  position: relative;
}
</style>
