<template>
  <nav>
    <div
      class="header-banner-container orange darken-1 d-flex align-center justify-center">
      <div class="header-banner d-flex justify-center align-center mx-sm-2">
        <div class="white--text font-weight-bold title-2">
          Já conhece nossa plataforma de gestão de compras?
        </div>
        <div class="ml-3">
          <div>
            <v-btn color="#010a18"
                   class="rounded-tag-small white--text normal-btn-text elevation-0"
                   href="https://gestao.proxpect.com.br?utm_source=botao&utm_medium=pesquisa&utm_campaign=mktplace&utm_id=gestao"
                   target="_blank">
              Quero conhecer
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="header-container d-flex flex-column align-center justify-center">
      <div class="header d-flex" v-if="!isSmall">
        <div class="d-flex align-center">
          <router-link to="/home">
            <img class="header-logo" src="../../assets/logo-tagline.svg"
                 alt="Logo da Proxpect"/>
          </router-link>
        </div>
        <div class="d-flex flex-grow-1 align-center">
          <px-search-bar class="mx-8"/>
        </div>
        <div class="d-flex align-center">
          <px-header-user :is-logged="isLogged"/>
          <px-notificacoes v-if="isLogged"
                           :left="false"
                           class="ml-3"
                           badge-color="primary"
                           badge-class="large-badge"
                           icon-class="x-large-icon"
                           btn-class="x-large-button"
                           icon="mdi-bell-outline"/>
          <px-carrinho-button/>
        </div>
      </div>
      <div v-else class="d-flex flex-column mt-5 mb-5">
        <div class="d-flex justify-center align-start">
          <router-link to="/home">
            <img class="header-logo-small" src="../../assets/logo.svg"
                 alt="Logo da Proxpect"/>
          </router-link>
        </div>
        <div class="d-flex flex-grow-1 align-center mt-5">
          <px-search-bar height="50px" class="mx-8"/>
        </div>
        <div class="d-flex justify-center mt-5">
          <px-header-user :is-logged="isLogged"/>
          <px-notificacoes v-if="isLogged" icon="mdi-bell-outline" badge-color="primary"/>
          <px-carrinho-button/>
        </div>
      </div>
      <div class="header-options">
        <px-menu-ofertas/>
      </div>
    </div>
  </nav>
</template>

<script>
import PxCarrinhoButton from '@/modules/carrinho/PxCarrinhoButton.vue';
import { mapGetters } from 'vuex';
import SearchBar from '../search-bar/SearchBar.vue';
import HeaderUser from './HeaderUser.vue';
import MenuOfertas from '../menu-ofertas/MenuOfertas.vue';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isLogged']),
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isXs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    goToHome() {
      if (this.$router.currentRoute.name === 'public.home') {
        return;
      }
      this.$router.push({ name: 'public.home' });
    },
  },
  components: {
    PxCarrinhoButton,
    pxSearchBar: SearchBar,
    pxHeaderUser: HeaderUser,
    pxMenuOfertas: MenuOfertas,
  },
};
</script>

<style scoped>
.header-container {
  top: 0;
  left: 0;
  width: 100%;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  opacity: 1;
}

@media (min-width: 960px) {
  .header-container {
    height: 230px;
  }
}

.header-logo {
  width: 260px;
}

.header-logo-small {
  width: 100px;
}

.header {
  width: 100%;
  max-width: 1280px;
  height: 160px;
}

.header-options {
  width: 100%;
  max-width: 1280px;
}

.header-banner {
  width: 100%;
  max-width: 1280px;
}

.header-banner-container {
  height: 50px;
}
</style>
