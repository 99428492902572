<template>
  <v-card class="rounded-card-medium card-shadow">
    <v-card-text :class="colorClass">
      <div class="d-flex">
        <div class="d-flex align-center">
          <slot name="icon"/>
        </div>
        <div class="ml-3 text--primary">
          <slot/>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    colorClass: {
      type: String,
      default: 'border-right-primary',
    },
  },
};
</script>
