export default {
  methods: {
    pageToOptions(listOptions) {
      return {
        page: listOptions.page,
        itemsPerPage: listOptions.limit,
        sortBy: [(listOptions.sort)],
        sortDesc: [listOptions.sortDesc || false],
        multiSort: false,
      };
    },
    optionsToPage(page, options) {
      return {
        ...page,
        page: options.page,
        limit: options.itemsPerPage,
        offset: Math.floor((options.page - 1) * options.itemsPerPage),
        sort: options.sortBy[0],
        sortDesc: options.sortDesc[0],
      };
    },
    resetPagination(page) {
      return {
        ...page,
        page: 1,
        offset: 0,
      };
    },
    buildQuery(options) {
      let query = '';
      if (options.filter) {
        query += options.filter;
      }
      query = `${query} ${this.buildSortParam(options)}`;
      return query.trim();
    },
    buildSortParam(options) {
      if (!options.sort) {
        return '';
      }
      return `order by ${options.sort}${options.sortDesc ? ' desc' : ''}`;
    },
  },
};
