import axios from 'axios';

// http://localhost:8040/proxpect/api/compras/produtos_comprados?query=produto = 100478 and unidadeMedida=5 order by dataCompra desc
const getHistoricoPrecos = params => axios.get('/compras/produtos_comprados', {
  params,
});

export default {
  getHistoricoPrecos,
};
