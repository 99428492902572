<template>
  <div>
    +{{ percentual | number('0.00') }}% de IPI <b>não incluso</b>
    <px-oferta-imposto-nao-incluso-menu :oferta="oferta" :variacao="variacao" />
  </div>
</template>

<script>
import { NumberUtils } from 'px-components';
import PxOfertaImpostoNaoInclusoMenu from './PxOfertaImpostoNaoInclusoMenu.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    variacao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    percentual() {
      return NumberUtils.fixNumberInput(this.oferta.ipi.percentualImposto, true);
    },
  },
  components: {
    PxOfertaImpostoNaoInclusoMenu,
  },
};
</script>
