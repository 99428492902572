import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  paramName: 'sort',
  sortOptions: [
    {
      description: 'Relevância',
      field: 'disponivel desc, relevancia desc, totalAcesso desc',
    },
    {
      description: 'Mais vendidos',
      field: 'disponivel desc, totalCompras desc',
    },
    {
      description: 'Menor preço à vista',
      field: 'disponivel desc, precoAVista',
    },
    {
      description: 'Menor preço a prazo',
      field: 'disponivel desc, precoAPrazo',
    },
    {
      description: 'Por destaque',
      field: 'disponivel desc, destaque desc',
    },
    {
      description: 'Relevância da busca',
      field: 'disponivel desc',
      params: {
        relevancia: true,
      },
    },
  ],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
