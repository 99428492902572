<template>
  <v-menu
    open-on-hover
    offset-y
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="300"
    max-width="300"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon
             small
             v-on="{ ...on }"
             @click.stop.prevent=""
      >
        <v-icon small>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="text--primary">
        <div class="body-2">
          {{ infoText }}
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    infoText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menuOpened: false,
    };
  },
};
</script>
