<template>
  <v-btn x-large icon :color="btnColor"
         :disabled="loading"
         @click="onClickFavoritar">
    <v-icon x-large>
      {{ btnIcon }}
    </v-icon>
  </v-btn>
</template>

<script>
import OfertaService from '../oferta-service';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      favorito: null,
    };
  },
  computed: {
    btnIcon() {
      return this.favorito ? 'mdi-heart' : 'mdi-heart-outline';
    },
    btnColor() {
      return this.favorito ? 'red' : '';
    },
  },
  methods: {
    loadFavorito() {
      this.loading = true;
      return OfertaService.getOfertaFavorito(this.oferta.id)
        .then(({ data }) => {
          this.favorito = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addFavorito() {
      this.loading = true;
      return OfertaService.favoritar(this.oferta.id)
        .then(({ data }) => {
          this.favorito = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeFavorito() {
      this.loading = true;
      return OfertaService.removerFavorito(this.oferta.id, this.favorito.id)
        .then(() => {
          this.favorito = null;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickFavoritar() {
      if (this.favorito) {
        this.removeFavorito();
        return;
      }
      this.addFavorito();
    },
  },
  created() {
    this.loadFavorito();
  },
};
</script>
