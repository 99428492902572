import axios from '../modules/marketplace/axios-marketplace';

const getPrazosMarketplacePage = (idConfig, params) => axios.get(`/configuracoes-vendas/${idConfig}/prazos-pagamentos`, {
  params,
});

const getPrazosMarketplacePageCompleto = (idConfig, params) => axios.get(`/configuracoes-vendas/${idConfig}/prazos-pagamentos/completo`, {
  params,
});

export default {
  getPrazosMarketplacePage,
  getPrazosMarketplacePageCompleto,
};
