<template>
  <div>
    <px-oferta-title :oferta="oferta"
                     :is-partial-view="isPartialView"/>
  </div>
</template>

<script>
import OfertaTitle from './OfertaTitle.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    isPartialView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    pxOfertaTitle: OfertaTitle,
  },
};
</script>
