import VueCookies from 'vue-cookies';
import { COOKIES_NAME } from './tokens.constants';
import {
  SET_AUTH_TOKENS, INITIALIZE_FROM_COOKIES, CLEAR_TOKENS, SET_REDIRECT,
} from './mutations.type';

// const domain = process.env.VUE_APP_APP_DOMAIN;

export default {
  [SET_AUTH_TOKENS](state, tokens) {
    state.tokens = tokens;
    VueCookies.set(COOKIES_NAME, tokens);
  },
  [INITIALIZE_FROM_COOKIES](state) {
    if (state.tokens) {
      return;
    }
    state.tokens = VueCookies.get(COOKIES_NAME);
  },
  [CLEAR_TOKENS](state) {
    state.tokens = null;
    VueCookies.set(COOKIES_NAME, null);
  },
  [SET_REDIRECT](state, redirect) {
    state.redirect = redirect;
  },
};
