<template>
  <div class="body-2">
      <a @click="click">
        <v-icon color="primary">mdi-bell-outline</v-icon>
        Enviar contraproposta
        <v-chip label small color="error" class="ml-3">Novo</v-chip>
      </a>
  </div>
</template>

<script>
export default {
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
