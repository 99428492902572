import { TYPES } from '../modules/ofertas/types';
import Router from '../router';

function goToOfertas(type, value, newTab = false) {
  const routeParams = {
    name: 'public.ofertas',
    params: {
      type,
      value,
    },
  };
  if (newTab) {
    const route = Router.resolve(routeParams);
    window.open(route.href, '_blank');
    return new Promise((resolve) => { resolve(); });
  }
  return Router.push(routeParams);
}

function goToOfertasSearch(value, newTab = false) {
  return goToOfertas(TYPES.search.id, value, newTab);
}

function goToOfertasDepartamento(value, newTab = false) {
  return goToOfertas(TYPES.d.id, value, newTab);
}

function goToOfertasGrupo(value, newTab = false) {
  return goToOfertas(TYPES.g.id, value, newTab);
}

function goToOfertasCategoria(value, newTab = false) {
  return goToOfertas(TYPES.c.id, value, newTab);
}

function goToOfertasPromocoes(value = 'promo') {
  return goToOfertas(TYPES.p.id, value);
}

function goToOfertasPromocoesNewTab(value = 'promo') {
  return goToOfertas(TYPES.p.id, value, true);
}

function goToOfertasFavoritos(value = 'favoritos') {
  return goToOfertas(TYPES.f.id, value);
}

function goToOfertasFavoritosNewTab(value = 'favoritos') {
  return goToOfertas(TYPES.f.id, value, true);
}

function goToOfertasQueimaEstoqueNewTab(value = 'estoque') {
  return goToOfertas(TYPES.s.id, value, true);
}

function goToOfertasQueimaEstoque(value = 'estoque') {
  return goToOfertas(TYPES.s.id, value);
}

function setQueryParam(paramName, paramValue) {
  const query = { ...Router.currentRoute.query };
  if (query[paramName] === paramValue) {
    return null;
  }
  query[paramName] = paramValue;
  return Router.replace({ query });
}

export {
  goToOfertas,
  goToOfertasSearch,
  goToOfertasDepartamento,
  goToOfertasGrupo,
  goToOfertasCategoria,
  goToOfertasPromocoes,
  goToOfertasPromocoesNewTab,
  goToOfertasFavoritos,
  goToOfertasFavoritosNewTab,
  goToOfertasQueimaEstoque,
  goToOfertasQueimaEstoqueNewTab,
  setQueryParam,
};
