<template>
  <div>
    <v-menu
      content-class="rounded-menu"
      v-model="menuOpened"
      :close-on-content-click="true"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <a class="menu-link text--primary font-weight-bold"
               v-on="on">
          <v-icon>mdi-menu</v-icon>
          <span>
            {{ menuDescription }}
          </span>
        </a>
      </template>
      <v-card tile v-if="loading" class="text-center">
        <v-card-text>
          <px-circular-loading/>
        </v-card-text>
      </v-card>
      <v-card tile v-if="!loading && departamentos">
        <v-list class="pb-0 pt-0" id="menuDepartamentos">
          <template v-for="departamento in departamentosList">
            <px-departamento-menu @close="onClose"
                                  :key="departamento.id"
                                  :departamento="departamento"/>
          </template>
        </v-list>
        <px-todos-departamentos-menu
          class="mt-3 pb-5"
          @close="onClose"
          :todos-departamentos="departamentosTodos"/>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import DepartamentoMenu from './DepartamentoMenu.vue';
import TodosDepartamentosMenu from './TodosDepartamentosMenu.vue';
import MenuService from './menu-service';

const LIMIT_DEPARTAMENTOS = 10;

export default {
  props: {
    btnClass: String,
  },
  data: () => ({
    menuOpened: false,
    loading: false,
    departamentos: null,
    departamentosTodos: null,
  }),
  computed: {
    menuDescription() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'Departamentos';
      }
      return 'NAVEGUE POR DEPARTAMENTO';
    },
    hasLimitDepartamentos() {
      return this.departamentos.length > LIMIT_DEPARTAMENTOS;
    },
    departamentosList() {
      if (this.hasLimitDepartamentos) {
        return this.departamentos.slice(0, LIMIT_DEPARTAMENTOS);
      }
      return this.departamentos;
    },
  },
  methods: {
    onClose() {
      this.menuOpened = false;
    },
    getDepartamentos() {
      this.loading = true;
      MenuService.getDepartamentos()
        .then(({ data }) => {
          this.departamentos = data;
          this.departamentosTodos = this.departamentos;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getDepartamentos();
  },
  components: {
    pxDepartamentoMenu: DepartamentoMenu,
    pxTodosDepartamentosMenu: TodosDepartamentosMenu,
  },
};
</script>

<style scoped lang="scss">
@import './src/scss/variables';

.rounded-menu {
  border-radius: $default-border-radius 0 $default-border-radius $default-border-radius !important;
}
</style>
