<template>
  <div class="d-inline-block">
    <v-tooltip top v-if="isIcon">
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" target="_blank" link :to="`/fornecedor/${id}`">
          <v-icon small>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <span>Abrir página do fornecedor</span>
    </v-tooltip>
    <v-btn class="ml-2" x-small link
           v-if="!isIcon"
           target="_blank"
           :to="`/fornecedor/${id}`">
      Abrir página
      <v-icon x-small>mdi-open-in-new</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    id: [String, Number],
    isIcon: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default: 'Ver página',
    },
  },
};
</script>
