export default {
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    let filter = '';
    if (state.searchText) {
      const value = state.searchText.toLowerCase();
      filter += `fornecedor.nome like '%${value}%' or company.nome like '%${value}%' or tituloOferta like '%${value}%'`;
    }
    return filter;
  },
};
