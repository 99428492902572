<template>
  <div :align="align">
    <v-img v-if="fotoPath"
           :src="fotoPath"
           v-bind="$attrs"
           :alt="alt"
           :class="`responsive-image ${imgClass}`"
    >
      <slot name="image"></slot>
    </v-img>
    <v-img v-else
           v-bind="$attrs"
           :class="`responsive-image ${imgClass}`"
           :alt="alt"
           src="../assets/imagem-produto.png"
    >
      <slot name="image"></slot>
    </v-img>
  </div>
</template>

<script>
import FileUrlPathService from '@/api/file-url-path-service';

export default {
  props: {
    foto: {
      type: Object,
      required: false,
    },
    imgClass: {
      type: String,
      required: false,
    },
    align: {
      type: String,
      default: 'start',
    },
    alt: {
      type: String,
      default: '',
    },
  },
  computed: {
    fotoPath() {
      if (!this.foto) {
        return null;
      }
      return FileUrlPathService.getPath(this.foto.path);
    },
  },
};
</script>
