<template>
  <div>
    <v-text-field
      outlined
      hide-details
      label="CEP"
      v-mask="['#####-###']"
      class="required input-display-cep rounded-card-small"
      v-model="cep"
    />
    <v-btn x-large
           class="ml-2 normal-btn-text font-weight-bold rounded-card-small"
           color="primary"
           :loading="loading"
           @click="onClickCalcular">
      Calcular
    </v-btn>
  </div>
</template>

<script>
import GtmService from '@/api/gtm-service';
import { onlyNumbers } from '../../../utils/regex-utils';

const CEP_LENGTH = 8;

export default {
  props: {
    loading: Boolean,
  },
  data() {
    return {
      cep: null,
    };
  },
  methods: {
    onClickCalcular() {
      const cep = onlyNumbers(this.cep);
      if (cep.length !== CEP_LENGTH) {
        return;
      }
      this.$emit('change', this.cep);
      GtmService.push('search-cep', { cep });
    },
  },
};
</script>

<style scoped>
.input-display-cep {
  display: inline-block;
  max-width: 160px !important;
}
</style>
