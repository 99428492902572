import { render, staticRenderFns } from "./OfertaCardPreco.vue?vue&type=template&id=4bdcfbe1&scoped=true&"
import script from "./OfertaCardPreco.vue?vue&type=script&lang=js&"
export * from "./OfertaCardPreco.vue?vue&type=script&lang=js&"
import style0 from "./OfertaCardPreco.vue?vue&type=style&index=0&id=4bdcfbe1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bdcfbe1",
  null
  
)

export default component.exports