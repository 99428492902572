<template>
  <!--  class="mt-2" style="width: 98%; background-color: white"-->
  <v-container fluid class="app-container">
    <router-view></router-view>
    <px-verifier-dialog ref="verifier"/>
  </v-container>
</template>

<script>
import UserService from '@/api/user-service';
import { mapGetters } from 'vuex';
import VerifierDialog from './VerifierDialog.vue';

export default {
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    verifyUser() {
      UserService.hasComprador()
        .then(({ data }) => {
          if (data.hasAccess) {
            return;
          }
          this.$refs.verifier.open();
        });
    },
  },
  created() {
    if (this.isLogged) {
      this.verifyUser();
    }
  },
  components: {
    pxVerifierDialog: VerifierDialog,
  },
};
</script>

<style>
.app-container {
  border-radius: 2px;
  background-color: white;
  min-height: 500px;
  padding: 0;
}
</style>
