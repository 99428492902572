<template>
  <div class="text--primary--color">
    <v-icon color="success">mdi-whatsapp</v-icon>
    Gostou desta oferta?
    <px-share-whatsapp-btn :message="message">
      Compartilhe pelo Whatsapp</px-share-whatsapp-btn>.
  </div>
</template>

<script>
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    message() {
      return `Você vai gostar dessa oferta da Proxpect: ${this.oferta.titulo} - ${window.location.href}`;
    },
  },
};
</script>
