<template>
  <div id="historico-oferta" class="mt-8 ml-5">
    <div class="headline font-weight-bold-2">
      <px-collapse-btn large class="headline font-weight-bold-2 text--primary"
                       title="Histórico de preço"
                       icon-color="none"
                       v-model="showHistorico"/>
      <px-menu-info
        info-text="Aqui você pode verificar o histórico de preço para esse item."/>
      <v-chip label small color="error" class="ml-3">Novo</v-chip>
    </div>
    <v-expand-transition>
      <div v-show="showHistorico">
        <div>
          <small>
            Últimos 6 meses
          </small>
        </div>
        <v-row>
          <v-col cols="12" sm="8">
            <figure style="width: 100%" v-show="isLogged">
              <v-chart :options="chart"
                       ref="historicoPrecoChart"
                       theme="primary"
                       autoresize/>
            </figure>
            <px-historico-preco-thumb v-if="!isLogged"/>
          </v-col>
          <v-col cols="12" sm="4">
            <px-oferta-alerta-preco v-if="showHistorico"
                                    :oferta="oferta"/>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import chartMixin from '@/mixins/chart-mixin';
import { toEchartData } from '@/utils/chart-utils';
import moment from 'moment';
import {
  sortBy, first, last,
} from 'lodash';
import EventService from '@/events';
import axios from 'axios';
import { EventTypes } from 'px-business-components';
import { mapGetters } from 'vuex';
import OfertaService from '../oferta-service';
import OfertaAlertaPreco from './alerta-preco/OfertaAlertaPreco.vue';
import PxHistoricoPrecoThumb from './components/PxHistoricoPrecoThumb.vue';

const TIME_IN_MONTHS = 6;
const FIELD_A_VISTA = 'precoAVista';
const DATA_FORMAT = 'DD/MM/YYYY';

export default {
  mixins: [chartMixin],
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      initialDate: null,
      showHistorico: false,
      historico: null,
      historicoRecords: [],
      dataPoints: [],
      chart: null,
      data: [],
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    precoAVista() {
      return this.oferta.promocaoAtiva
        ? this.oferta.precoPromocionalAVista : this.oferta.precoAVista;
    },
  },
  methods: {
    showLoading() {
      this.$refs.historicoPrecoChart.showLoading(this.loadingConfig);
    },
    hideLoading() {
      this.$refs.historicoPrecoChart.hideLoading();
    },
    buildChart(data) {
      return {
        width: '85%',
        tooltip: {
          trigger: 'axis',
          formatter: this.tooltipFormatterAxis,
        },

        dataset: {
          source: [
            ['Saving'],
            ...toEchartData(data, 'description', 'value'),
          ],
        },
        xAxis: {
          type: 'category', interval: 1, maxInterval: 1, splitNumber: 1,
        },
        yAxis: {
          axisLabel: {
            formatter: this.axisMoneyFormatter,
          },
        },
        grid: {
          left: '10%',
        },
        series: [
          {
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3,
            },
            // mostra ou não os dots de cada ponto
            symbol: 'emptyCircle',
            showSymbol: false,
          },
        ],
      };
    },
    getHistorico() {
      if (!this.isLogged) {
        return;
      }
      this.showLoading();
      const dataInicio = this.initialDate.clone()
        .startOf('day')
        .format('YYYY-MM-DDThh:mm:ss');
      OfertaService.getHistorico(this.oferta.id, {
        dataInicio,
      })
        .then(({ data }) => {
          this.historico = data;
          this.mapDiffs();
          this.buildHistoricalData();
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    setupInitialDate() {
      const initialDate = moment().subtract(TIME_IN_MONTHS, 'month');
      const createdIn = moment(this.oferta.createdIn);
      if (initialDate.isBefore(createdIn)) {
        this.initialDate = createdIn;
        return;
      }
      this.initialDate = initialDate;
    },
    mapDiffs() {
      const historicos = this.historico.map((historico) => {
        const alteracaoPreco = historico.alteracoes
          .find(alteracao => alteracao.campo === FIELD_A_VISTA);
        const flatDate = first(historico.versao.data.split('T'));

        return {
          dateObject: moment(flatDate),
          date: historico.versao.data,
          flatDate,
          ...alteracaoPreco,
        };
      });
      this.historicoRecords = sortBy(historicos, 'date');
    },
    buildHistoricalData() {
      const now = moment();
      const date = this.initialDate.clone();
      const dataRecords = [];

      while (date.isSameOrBefore(now, 'day')) {
        if (!this.historicoRecords.length) {
          dataRecords.push({
            description: date.format(DATA_FORMAT),
            value: this.precoAVista,
            dateObject: date.clone(),
          });
          date.add(1, 'day');
          // eslint-disable-next-line no-continue
          continue;
        }

        const equal = last(this.historicoRecords
          .filter(record => record.dateObject.isSame(date, 'day')));

        if (equal) {
          dataRecords.push({
            description: date.format(DATA_FORMAT),
            value: equal.novo,
            dateObject: equal.dateObject,
          });
          date.add(1, 'day');
          // eslint-disable-next-line no-continue
          continue;
        }
        const nextRecord = this.historicoRecords
          .find(record => record.dateObject.isAfter(date, 'day'));
        if (nextRecord) {
          dataRecords.push({
            description: date.format(DATA_FORMAT),
            value: nextRecord.antigo,
            dateObject: nextRecord.dateObject,
          });
          date.add(1, 'day');
          // eslint-disable-next-line no-continue
          continue;
        }
        const lastRecord = last(this.historicoRecords);
        dataRecords.push({
          description: date.format(DATA_FORMAT),
          value: lastRecord.novo,
          dateObject: lastRecord.dateObject,
        });
        date.add(1, 'day');
      }
      this.dataPoints = dataRecords;
      this.chart = this.buildChart(this.dataPoints);
    },
    fireEvent() {
      EventService.createAcessoTarget(axios, EventTypes.Resource.HISTORICO_OFERTA,
        { value: this.oferta.id }, this.oferta.id);
    },
    openHistory() {
      this.showHistorico = true;
      document.querySelector('#historico-oferta')
        .scrollIntoView({ behavior: 'smooth' });
    },
  },
  watch: {
    showHistorico(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (this.showHistorico) {
        this.getHistorico();
        this.fireEvent();
      }
    },
  },
  created() {
    this.setupInitialDate();
  },
  components: {
    pxOfertaAlertaPreco: OfertaAlertaPreco,
    PxHistoricoPrecoThumb,
  },
};
</script>

<style scoped>
figure .echarts {
  width: 100%;
  /*max-width: 550px;*/
}
</style>
