<template>
  <px-lateral-dialog :fullscreen="isMobile"
                     v-model="dialog"
                     width="600">
    <template v-slot:activator="{ on }">
      <v-btn x-large
             color="secondary"
             class="normal-btn-text font-weight-bold rounded-card-small elevation-0"
             :disabled="cotacaoEnviada"
             v-on="{ ...on, click: onClickDialog }">
        <v-icon left>mdi-send-outline</v-icon>
        Solicitar cotação
      </v-btn>
    </template>
    <template v-slot:title>
      Solicitar cotação ({{ quantidadeCotacao }})
    </template>
    <template>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            hide-details
            class="required rounded-card-small"
            autocomplete="off"
            label="CNPJ"
            v-mask="['##.###.###/####-##']"
            :error="$v.cotacao.cnpj.$error"
            @blur="$v.cotacao.cnpj.$touch()"
            v-model="cotacao.cnpj"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            label="Empresa"
            class="required rounded-card-small"
            maxlength="150"
            :error="$v.cotacao.empresa.$error"
            @blur="$v.cotacao.empresa.$touch()"
            v-model="cotacao.empresa"
          />
        </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              hide-details
              label="Nome do responsável"
              class="required rounded-card-small"
              maxlength="150"
              :error="$v.cotacao.nome.$error"
              @blur="$v.cotacao.nome.$touch()"
              v-model="cotacao.nome"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            type="email"
            label="E-mail empresarial"
            class="required rounded-card-small"
            maxlength="100"
            :error="$v.cotacao.email.$error"
            @blur="$v.cotacao.email.$touch()"
            v-model="cotacao.email"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            label="Telefone"
            class="required rounded-card-small"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :error="$v.cotacao.telefone.$error"
            @blur="$v.cotacao.telefone.$touch()"
            v-model="cotacao.telefone"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            label="Mensagem"
            counter="1500"
            rows="3"
            class="rounded-card-small"
            v-model="cotacao.mensagem"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn class="normal-btn-text elevation-0" @click="close">
        Fechar
      </v-btn>
      <v-btn color="primary"
             class="normal-btn-text elevation-0"
             :loading="loadingCotacao"
             @click="onClickEnviar">
        Enviar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import { email, required } from 'vuelidate/lib/validators';
import { isValid } from '@/utils/validate-cnpj';
import validationMixin from '@/mixins/validation-mixin';
import OfertaCotacaoService from '@/modules/ofertas/oferta/oferta-cotacao-service';
import { onlyNumbers } from '@/utils/regex-utils';

const initialState = () => (
  {
    cnpj: null,
    empresa: null,
    nome: null,
    email: null,
    telefone: null,
    mensagem: null,
  }
);

export default {
  mixins: [gridMixin, validationMixin],
  props: {
    quantidade: {
      type: [Number, String],
    },
    oferta: {
      type: Object,
      required: true,
    },
    variacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loadingCotacao: false,
      cotacaoEnviada: false,
      cotacao: initialState(),
    };
  },
  computed: {
    quantidadeCotacao() {
      return `${this.quantidade} ${this.oferta.unidadeMedida.descricao}`;
    },
  },
  methods: {
    buildPayload() {
      return {
        ...this.cotacao,
        cnpj: onlyNumbers(this.cotacao.cnpj),
        telefone: onlyNumbers(this.cotacao.telefone),
        ofertas: [{
          quantidade: this.quantidade,
          oferta: {
            id: this.oferta.id,
          },
        }],
      };
    },
    onClickEnviar() {
      if (this.validateForm()) {
        return;
      }
      this.loadingCotacao = true;
      OfertaCotacaoService.solicitarCotacao(this.buildPayload())
        .then(() => {
          this.$toast('Sua cotação foi enviada. Em breve entraremos em contato.', { color: 'success' });
          this.cotacaoEnviada = true;
          this.close();
        })
        .finally(() => {
          this.loadingCotacao = false;
        });
    },
    close() {
      this.dialog = false;
    },
    onClickDialog() {
      if (!this.quantidade) {
        this.$emit('invalid');
        return;
      }
      this.dialog = true;
    },
  },
  validations: {
    cotacao: {
      cnpj: {
        required,
        isValid,
      },
      empresa: {
        required,
      },
      nome: {
        required,
      },
      email: {
        required,
        email,
      },
      telefone: {
        required,
      },
    },
  },
};
</script>
