<template>
  <div class="mt-2">
    <px-regime-tributario v-if="isLogged" :short-description="true"
                          color="primary"
                          chip-class="ml-0 mr-2"
                          class="text-uppercase rounded-tag-small"
                          :regime-tributario="oferta.fornecedor.pessoa.regimeTributario"/>
    <px-oferta-ipi :oferta="oferta"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PxOfertaIpi from './components/PxOfertaIpi.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  components: {
    PxOfertaIpi,
  },
};
</script>
