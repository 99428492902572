<template>
  <div>
      <span class="body-2" v-if="isLogged">
        <span class="text--secondary">Vendido e entregue por</span>&nbsp;
        <a :href="urlFornecedor" target="_blank">
          {{ oferta.fornecedor.pessoa.nome }}
        </a>
      </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const APP_URL = process.env.VUE_APP_APP_URL;

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
    urlFornecedor() {
      return `${APP_URL}/fornecedor/${this.oferta.fornecedor.id}`;
    },
  },
};
</script>
