import axios from 'axios';

const getDefault = () => axios.get('/comprador/default');
const getComprador = () => axios.get('/comprador');
const getCompradorById = id => axios.get(`/comprador/${id}`);
const getCompradorPage = params => axios.get('/comprador/page', { params });

export default {
  getDefault,
  getComprador,
  getCompradorById,
  getCompradorPage,
};
