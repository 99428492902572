import Vue from 'vue';
import Vuex from 'vuex';
import login from '@/modules/login/store';
import auth from '@/store/auth';
import user from '@/store/user';
import carrinho from '@/store/carrinho';
import ofertasList from '@/store/ofertas-list';
import listagemCompras from '@/store/listagem-compras';
import logged from '@/store/logged';
import compare from '@/store/compare';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    auth,
    logged,
    user,
    carrinho,
    ofertasList,
    listagemCompras,
    compare,
  },
});
