<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    hide-selected
    hide-no-data
    no-filter
    multiple
    chips
    small-chips
    deletable-chips
    item-text="id"
    item-value="id"
    label="Item de cotação"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @blur="onBlur"
    @focus="onFocus"
    v-bind="$attrs"
  >
    <template v-slot:item="item">
      <v-list-item-content>
        <v-list-item-title>
          ({{ item.item.id }}) {{ item.item.produto.descricao }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.item.especificacaoProduto }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="data">
      <v-chip close @click:close="onClose(data.item)">
        ({{ data.item.id }}) {{ data.item.produto.descricao }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import { remove } from 'lodash';
import CotacaoProdutoService from '@/api/cotacao-produto-service';

export default {
  props: {
    value: Array,
    path: {
      type: String,
      default: null,
    },
    cotacoes: Array,
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
  },
  methods: {
    onClose(item) {
      remove(this.localValue, item);
      this.localValue = [...this.localValue];
    },
    getCotacoes() {
      if (!this.cotacoes || !this.cotacoes.length) {
        return [];
      }
      return this.cotacoes.map(cot => cot.id).join(',');
    },
    buildFilter(val) {
      const text = val || '';
      // todo: ajustar filtro
      let filter = `descricao like "%${text.toLowerCase()}%"`;
      if (this.value && this.value.length) {
        const ids = this.value.map(cot => cot.id)
          .join(' and id != ');
        filter = `${filter} and id != ${ids}`;
      }
      return filter;
    },
    searchProdutos(val) {
      this.loading = true;
      CotacaoProdutoService.getCotacoesProdutos(this.path, {
        cotacoes: this.getCotacoes(),
        query: this.buildFilter(val),
      })
        .then((response) => {
          this.items = response.data;
          if (this.value && this.value.length) {
            this.items = [...this.items, ...this.value];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onFocus() {
      this.debounceSearch(null);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchProdutos, 1000);
    if (this.value) {
      this.items = [this.value];
    }
  },
};
</script>
