<template>
  <v-footer class="pt-3 pb-3" color="white">
    <v-row>
      <v-col cols="12">
        <px-collapse-footer-component class="body-1" v-model="showDetailsInstitucional">
          <span class="font-weight-bold-2">Institucional</span>
          <template v-slot:content>
            <div class="ml-5 mt-3 mb-3 body-2">
              <div>
                <router-link class="body-2 no-decoration text--primary" to="/quem-somos">
                  Quem somos
                </router-link>
              </div>
              <div class="mt-3">
                <router-link class="body-2 no-decoration text--primary" to="/para-empresas">
                  Para empresas
                </router-link>
              </div>
              <div class="mt-3">
                <router-link class="body-2 no-decoration text--primary" to="/para-fornecedores">
                  Para fornecedores
                </router-link>
              </div>
            </div>
          </template>
        </px-collapse-footer-component>
        <px-collapse-footer-component class="body-1" v-model="showDetailsAtendimento">
          <span class="font-weight-bold-2">Atendimento</span>
          <template v-slot:content>
            <div class="ml-5 mt-3 mb-3 body-2">
              <div>
                <router-link class="body-2 no-decoration text--primary" to="/faq">
                  Perguntas frequentes
                </router-link>
              </div>
              <div class="mt-3">
                <router-link class="no-decoration text--primary" to="/central-ajuda">
                  Fale conosco
                </router-link>
              </div>
            </div>
          </template>
        </px-collapse-footer-component>
        <px-collapse-footer-component class="body-1" v-model="showDetailsFaleConosco">
          <span class="font-weight-bold-2">Fale conosco</span>
          <template v-slot:content>
            <div class="ml-5 mt-3 mb-3 body-2">
              <div>
                Vendas: (48) 99212-0020
              </div>
              <div class="mt-3">
                Suporte: (48) 98439-4452
              </div>
              <div class="mt-3">
                contato@proxpect.com.br
              </div>
              <div class="mt-2">
                Atendimento de segunda à sexta das 8h às 18h.
              </div>
            </div>
          </template>
        </px-collapse-footer-component>
        <px-collapse-footer-component class="body-1" v-model="showDetailsServicos">
          <span class="font-weight-bold-2">Serviços</span>
          <template v-slot:content>
            <div class="ml-5 mt-3 mb-3 body-2">
              <div>
                <a href="https://fornecedor.proxpect.com.br"
                   target="_blank"
                   class="no-decoration text--primary">Portal Fornecedor</a>
              </div>
              <div class="mt-3">
                <a href="https://blog.proxpect.com.br"
                   target="_blank"
                   class="no-decoration text--primary">Blog</a>
              </div>
            </div>
          </template>
        </px-collapse-footer-component>
        <div class="mt-5">
          <v-btn
            target="_blank"
            href="https://www.instagram.com/proxpect/"
            class="mx-2"
            icon
            large
          >
            <v-icon large size="24px">
              mdi-instagram
            </v-icon>
          </v-btn>
          <v-btn
            target="_blank"
            href="https://www.linkedin.com/company/proxpect/"
            class="mx-2"
            icon
            large
          >
            <v-icon size="24px" large>
              mdi-linkedin
            </v-icon>
          </v-btn>
          <v-btn
            target="_blank"
            href="https://www.facebook.com/StartupProxpect"
            class="mx-2"
            icon
            large
          >
            <v-icon size="24px" large>
              mdi-facebook
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div style="width: 100%">
      <v-row dense class="mt-3">
        <v-col cols="12" offset-sm="1">
          <span class="caption">
            Copyrights {{ new Date().getFullYear() }} ©
            <strong>Proxpect</strong> - Todos os direitos reservados
          </span>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>

<script>
import PxCollapseFooterComponent from './PxCollapseFooterComponent.vue';

export default {
  data: () => ({
    showDetailsInstitucional: false,
    showDetailsAtendimento: false,
    showDetailsFaleConosco: false,
    showDetailsServicos: false,
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),
  components: {
    PxCollapseFooterComponent,
  },
};
</script>
