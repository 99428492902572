<template>
  <div>
    <px-lista-fornecedor-header :values="value"
                                :comprador="comprador"
                                @update="onChangeFilter"
                                @resetfornecedor="emitReset"/>
    <px-lista-fornecedor-list :fornecedores="fornecedores"
                              :options-list="optionsList"
                              :loading="loading"
                              v-model="localValue"
                              :on-change-options="onChangeOptions"/>
  </div>
</template>

<script>
import { isEmpty, get } from 'lodash';
import FornecedorService from '@/api/fornecedor-service';
import paginationMixin from '@/mixins/pagination-mixin';
import PxListaFornecedorList from './PxListaFornecedorList.vue';
import PxListaFornecedorHeader from './PxListaFornecedorHeader.vue';

export default {
  mixins: [paginationMixin],
  props: {
    value: Array,
    comprador: Object,
    produtos: Array,
  },
  data() {
    return {
      loading: false,
      filters: {
        includeProdutos: true,
      },
      fornecedores: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'createdIn',
        sortDesc: true,
      },
      optionsList: {},
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    emitReset() {
      this.$emit('resetfornecedor');
    },
    onChangeFilter(filters) {
      this.filters = filters;
      this.resetPage();
      this.getFornecedores();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getFornecedores();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getFornecedores() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      FornecedorService.getFornecedoresScore({
        limit,
        offset,
        query: this.buildFilter(),
        produtos: this.getFilterProdutos(),
      })
        .then(({ data }) => {
          this.fornecedores = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    joinFilter(filters) {
      return filters.filter(f => !!f)
        .join(' and ');
    },
    buildSearchText() {
      const cnpj = this.filters.searchText.replaceAll(/(\.)|(\/)|(-)/g, '');
      return `nome like "%${this.filters.searchText.toLowerCase()}%" or cnpj like "%${cnpj}%"`;
    },
    buildFilter() {
      const filters = [];
      if (!this.filters) {
        return this.joinFilter(filters);
      }
      if (this.filters.searchText) {
        filters.push(this.buildSearchText());
      }
      filters.push(this.buildTagFilter());
      return this.joinFilter(filters);
    },
    buildTagFilter() {
      if (!this.filters || !get(this.filters, 'selectedStatus.property')) {
        return null;
      }
      return `${this.filters.selectedStatus.property} = ${this.filters.selectedStatus.value}`;
    },
    getFilterProdutos() {
      if (isEmpty(this.produtos) || !this.filters.includeProdutos) {
        return undefined;
      }
      const ids = this.produtos.map((prod) => {
        if (prod.produto.origem) {
          return prod.produto.origem.id;
        }
        return prod.produto.id;
      });
      return `${ids.join(',')}`;
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
  components: {
    PxListaFornecedorList,
    PxListaFornecedorHeader,
  },
};
</script>
