<template>
  <div :class="divClass" v-if="dateLimit">
    <countdown :end-time="timestampDate">
      <template
        v-slot:process="timeScope">
        <div class="caption font-weight-bold text--secondary--color">{{ text }}</div>
        <v-chip color="secondary"
                :class="`pa-5 rounded-tag-small ${tagClass(timeScope)}`"
                align="center">
          <v-icon left>mdi-alarm</v-icon>
          <span v-if="hasTime(timeScope.timeObj, 'd')">
          {{ timeScope.timeObj.d }}d&nbsp;
        </span>
          <span>
          {{ timeScope.timeObj.h }}h&nbsp;
        </span>
          <span>
          {{ timeScope.timeObj.m }}m&nbsp;
        </span>
          <span>
          {{ timeScope.timeObj.s }}s&nbsp;
        </span>
        </v-chip>
      </template>
      <template
        v-slot:finish>
        <span>Promoção encerrada</span>
      </template>
    </countdown>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Promoção acaba em:',
    },
    divClass: {
      type: String,
      default: '',
    },
    dateLimit: {
      required: true,
      type: String,
    },
  },
  computed: {
    timestampDate() {
      return Date.parse(this.dateLimit);
    },
    hasTime() {
      return (timeObj, unit) => timeObj[unit] !== '0';
    },
    tagClass() {
      return (timeObj) => {
        if (this.hasTime(timeObj.timeObj, 'd')) {
          return 'promocao-timer-large';
        }
        return 'promocao-timer-small';
      };
    },
  },
};
</script>

<style scoped>
.promocao-timer-small {
  min-width: 155px;
}

.promocao-timer-large {
  min-width: 190px;
}
</style>
