import Vue from 'vue';
import { NotificacaoPlugin } from 'px-notifications';

import 'px-notifications/dist/pxNotifications.css';

Vue.use(NotificacaoPlugin, {
  SOCKET_URL_NOTIFICATIONS: process.env.VUE_APP_SOCKET_URL_NOTIFICATIONS,
  API_URL_NOTIFICATIONS: process.env.VUE_APP_API_URL_NOTIFICATIONS,
  SYSTEM: 'MARKETPLACE',
  URL_PROXPECT: process.env.VUE_APP_GESTAO_URL,
  URL_PROXPECT_COMPRA_CONJUNTA: process.env.VUE_APP_URL_PROXPECT_COMPRA_CONJUNTA,
  URL_PROXPECT_MARKETPLACE: process.env.VUE_APP_URL_PROXPECT_MARKETPLACE,
  WINDOW_TITLE: 'Proxpect | Encontre tudo para a sua empresa',
  KEEP_TITLE: true,
});
