<template>
  <div>
    <v-row v-if="title">
      <v-col cols="12">
        <span :class="classTitle">
          {{ title }}
        </span>
        <router-link v-if="showLink"
                     class="ml-3"
                     :target="linkTarget"
                     :to="link">{{ linkText }}</router-link>
        <v-slide-group
          :class="`${marginClassTitle} px-slider`"
          v-model="slideState"
          active-class="none"
          show-arrows
        >
          <v-slide-item
            v-for="oferta in ofertas"
            :key="oferta.id"
          >
            <px-oferta-card class="mr-4"
                            :show-price="showPrice"
                            :open-new-tab="openNewTab"
                            :oferta="oferta"/>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    ofertas: Array,
    classTitle: {
      type: String,
      default: 'headline font-weight-bold',
    },
    marginClassTitle: {
      type: String,
      default: 'mb-5 mt-12',
    },
    openNewTab: {
      type: Boolean,
      default: false,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      default: '/',
    },
    linkText: {
      type: String,
      default: 'ver tudo',
    },
    linkTarget: {
      type: String,
      default: '_self',
    },
  },
  data() {
    return {
      slideState: null,
    };
  },
};
</script>
