import axios from '../marketplace/axios-marketplace';

const getCompleteEntity = (url, id) => axios.get(`${url}${id}`);
const queryOfertas = params => axios.get('/ofertas', { params });
const queryPagamentos = params => axios.get('/ofertas/list-pagamentos', { params });
const getOferta = id => axios.get(`/ofertas/${id}`);
const getFreteCep = (idConfiguracao, cep) => axios.get(`/configuracoes-fretes/${idConfiguracao}/cep/${cep}`);

const getEntityFilter = (path, params) => axios.get(path, {
  params,
});
const getFornecedoresFilter = params => axios.get('/ofertas/fornecedores', {
  params,
});
const getCategoriasFilter = params => axios.get('/ofertas/categorias', { params });


const criarDuvida = (id, data) => axios.post(`/ofertas/${id}/perguntas`, data);
const getDuvidas = (id, params) => axios.get(`ofertas/${id}/perguntas`, { params });
const getOfertaFavorito = id => axios.get(`/ofertas/${id}/favoritar`);
const favoritar = id => axios.post(`/ofertas/${id}/favoritar`, {});
const removerFavorito = (id, idFavorito) => axios.delete(`/ofertas/${id}/favoritar/${idFavorito}`, {});
const getHistorico = (id, params) => axios.get(`/ofertas/${id}/historico`, { params });
const getUltimasCompras = params => axios.get('/ofertas/ultimas-compradas', { params });

const getFreteCepIntegracao = (idVariacao, data) => axios.post(`ofertas-variacoes/${idVariacao}/fretes/cotar`, data);

export default {
  getCompleteEntity,
  queryOfertas,
  queryPagamentos,
  getOferta,
  getFreteCep,
  getEntityFilter,
  getFornecedoresFilter,
  criarDuvida,
  getDuvidas,
  getOfertaFavorito,
  favoritar,
  removerFavorito,
  getCategoriasFilter,
  getHistorico,
  getUltimasCompras,
  getFreteCepIntegracao,
};
