<template>
  <div class="mt-10">
    <v-row dense class="mb-3" v-if="variacao.disponivel && showSelecaoComprador">
      <v-col cols="12" class="body-1 font-weight-bold">Comprar para</v-col>
    </v-row>
    <div v-if="variacao.disponivel && isLogged">
      <div class="mb-3">
        <px-autocomplete-comprador
          required
          :clearable="false"
          class="required rounded-card-small"
          v-if="showSelecaoComprador"
          :error="$v.comprador.$error"
          @blur="$v.comprador.$touch()"
          @change="onChangeEmpresa"
          v-model="comprador"/>
      </div>
      <div>
        <px-input-number required
                         persistent-hint
                         :hide-details="false"
                         label="Quantidade"
                         class="required rounded-card-small"
                         autocomplete="off"
                         :hint="quantidadeMinima"
                         :error="$v.quantidade.$error"
                         @blur="$v.quantidade.$touch()"
                         @change="onChangeQuantidade"
                         v-model="quantidade"/>
      </div>
      <div class="caption ml-3" v-if="variacao.estoqueDisponivel">
        <v-icon left>mdi-package-variant-closed</v-icon>
        <b>{{ variacao.estoqueDisponivel }} {{ oferta.unidadeMedida.descricao }}</b>
        em estoque
      </div>
      <div class="mt-5">
        <v-btn x-large color="secondary"
               class="normal-btn-text font-weight-bold rounded-card-small elevation-0"
               :loading="loadingAdd"
               @click="onAddCarrinho">
          <v-icon left large>mdi-cart-outline</v-icon>
          Adicionar ao carrinho
        </v-btn>
      </div>
    </div>
    <div v-if="variacao.disponivel && !isLogged">
      <px-go-to-login-inline>
        <template v-slot:activator="{ on }">
          <span class="body-1">
            <a v-on="on">Faça login</a> para ver mais detalhes desta oferta.
          </span>
        </template>
      </px-go-to-login-inline>
    </div>
    <div v-if="!variacao.disponivel" class="text--primary body-1">
      <v-icon class="mr-2" color="error">mdi-alert-outline</v-icon>
      Este item não está disponível
    </div>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import { first } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CompradorService from '@/api/comprador-service';
import { ADD_ITEM } from '@/store/carrinho/actions.type';
import GtmService from '@/api/gtm-service';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    variacao: {
      type: Object,
      required: true,
    },
    validateFunction: {
      type: Function,
      required: true,
    },
    quantidadeDefault: {
      type: [String, Number],
      default: 0,
    },
    compradorDefault: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showSelecaoComprador: false,
      quantidade: null,
      comprador: null,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    ...mapGetters('carrinho', ['loadingAdd']),
    quantidadeMinima() {
      return `* Mínimo ${this.variacao.quantidade} ${this.oferta.unidadeMedida.descricao}.`;
    },
  },
  methods: {
    ...mapActions('carrinho', {
      addItem: ADD_ITEM,
    }),
    onChangeQuantidade() {
      this.$emit('quantidade', this.quantidade);
    },
    onChangeEmpresa() {
      this.$emit('comprador', this.comprador);
    },
    getComprador() {
      CompradorService.getComprador()
        .then(({ data }) => {
          if (this.compradorDefault) {
            this.comprador = this.compradorDefault;
          } else {
            [this.comprador] = data;
          }
          this.onChangeEmpresa();
          if (data.length > 1) {
            this.showSelecaoComprador = true;
            return;
          }
          this.showSelecaoComprador = false;
        });
    },
    getOfertaId() {
      if (this.oferta.possuiVariacao) {
        return this.variacao.id;
      }
      const variacaoPadrao = first(this.oferta.variacoes);
      return variacaoPadrao.id;
    },
    getOferta() {
      if (this.oferta.possuiVariacao) {
        return this.variacao;
      }
      return first(this.oferta.variacoes);
    },
    sendGAEvent() {
      const oferta = this.getOferta();
      return {
        currency: 'BRL',
        items: [{
          item_id: oferta.id,
          item_name: this.oferta.titulo,
          price: oferta.precoAVista,
          currency: 'BRL',
          quantity: this.quantidade,
        }],
        value: this.quantidade * oferta.precoAVista,
      };
    },
    buildItem() {
      return {
        company: this.comprador,
        oferta: {
          id: this.getOfertaId(),
        },
        quantidade: this.quantidade,
      };
    },
    showAlertEstoque() {
      this.$refs.confirm.open('Podemos não ter essa quantidade em estoque.',
        'O fornecedor pode recusar a compra por falta de estoque. Deseja continuar?',
        { width: 500 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.validateAndAdd();
        });
    },
    onAddCarrinho() {
      if (this.variacao.estoqueDisponivel && this.variacao.estoqueDisponivel < this.quantidade) {
        this.showAlertEstoque();
        return;
      }
      this.validateAndAdd();
    },
    validateAndAdd() {
      const isInvalidOtherFields = this.validateFunction();
      if (this.validateForm() || isInvalidOtherFields) {
        return;
      }
      this.addItem({ payload: this.buildItem() })
        .then(() => {
          GtmService.buildPush('add_to_cart', this.sendGAEvent);
          this.$toast('Item adicionado ao carrinho', { color: 'success' });
          this.quantidade = null;
          this.$v.$reset();
          this.$emit('addedcarrinho');
        });
    },
  },
  created() {
    if (this.isLogged) {
      this.getComprador();
    }

    if (this.quantidadeDefault) {
      this.quantidade = this.quantidadeDefault;
      this.onChangeQuantidade();
    }
  },
  validations: {
    quantidade: {
      required,
    },
    comprador: {
      required,
    },
  },
};
</script>
