import {
  SHOW_BAR_ACT,
  CLOSE_BAR_ACT,
  CLEAR_OFERTAS_ACT,
  ADD_OFERTA_ACT,
  REMOVE_OFERTA_ACT,
  LOAD_INITIAL_STATE_ACT,
} from './actions.type';
import {
  SHOW_BAR, CLOSE_BAR, CLEAR_OFERTAS, ADD_OFERTA, REMOVE_OFERTA, LOAD_INITIAL_STATE,
} from './mutations.type';

export default {
  [SHOW_BAR_ACT](context) {
    context.commit(SHOW_BAR);
  },
  [CLOSE_BAR_ACT](context) {
    context.commit(CLOSE_BAR);
  },
  [CLEAR_OFERTAS_ACT](context) {
    context.commit(CLEAR_OFERTAS);
  },
  [ADD_OFERTA_ACT](context, oferta) {
    context.commit(ADD_OFERTA, oferta);
  },
  [REMOVE_OFERTA_ACT](context, oferta) {
    context.commit(REMOVE_OFERTA, oferta.id);
  },
  [LOAD_INITIAL_STATE_ACT](context) {
    context.commit(LOAD_INITIAL_STATE);
  },
};
