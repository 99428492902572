import {
  SET_ITENS, LOADING, LOADING_ADD,
} from './mutations.type';

export default {
  [SET_ITENS](state, itens) {
    state.itens = itens;
  },
  [LOADING](state, loading) {
    state.loading = loading;
  },
  [LOADING_ADD](state, loading) {
    state.loadingAdd = loading;
  },
};
