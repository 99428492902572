<template>
  <px-lateral-dialog v-model="localValue"
                     width="450"
                     content-class="rounded-card">
    <template v-slot:title>
      Enviar contraproposta
    </template>
    <template>
      <div>
        Informe seu e-mail e envie a contraproposta. Você será notificado no e-mail quando a
        proposta atingir:
        <span class="font-weight-bold">{{ alerta.valor | currency }}</span>
      </div>
      <div class="mt-5">
        <v-text-field
          outlined
          hide-details
          label="E-mail"
          type="email"
          name="email"
          class="required rounded-card-small"
          maxlength="150"
          :error="$v.email.$error"
          @blur="$v.email.$touch()"
          v-model="email"
        />
      </div>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn color="primary"
             class="rounded-tag-small normal-btn-text elevation-0 mr-3"
             :loading="loading"
             @click="onClickCriar">
        <v-icon left>mdi-bell-outline</v-icon>
        Enviar contraproposta
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';

export default {
  mixins: [validationMixin],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    alerta: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      email: null,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    onClickCriar() {
      if (this.validateForm()) {
        return;
      }
      this.$emit('create', this.email);
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>
