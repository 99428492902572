<template>
  <div class="mt-10">
    <template>
      <v-row dense>
        <v-col cols="12">
          <div class="mb-3">
            <px-autocomplete-comprador
              required
              :clearable="false"
              class="required rounded-card-small"
              v-if="showSelecaoComprador"
              :error="$v.comprador.$error"
              @blur="$v.comprador.$touch()"
              v-model="comprador"/>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <px-input-number required
                           persistent-hint
                           :hide-details="false"
                           label="Quantidade"
                           class="required rounded-card-small"
                           autocomplete="off"
                           :hint="quantidadeMinima"
                           :error="$v.quantidade.$error"
                           @blur="$v.quantidade.$touch()"
                           v-model="quantidade"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <template v-if="isLogged">
            <v-btn x-large
                   color="secondary"
                   class="normal-btn-text font-weight-bold rounded-card-small elevation-0"
                   :loading="loadingCotacao"
                   :disabled="cotacaoEnviada"
                   @click="onClickSolicitarCotacao">
              <v-icon left>mdi-send-outline</v-icon>
              Solicitar cotação
            </v-btn>
          </template>
          <template v-else>
            <px-solicitar-cotacao-dialog :quantidade="quantidade"
                                         :oferta="oferta"
                                         :variacao="variacao"
                                         @invalid="onInvalidDialog"/>
          </template>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CompradorService from '@/api/comprador-service';
import { mapGetters } from 'vuex';
import OfertaCotacaoService from '@/modules/ofertas/oferta/oferta-cotacao-service';
import SolicitarCotacaoDialog from './cotacao/SolicitarCotacaoDialog.vue';

export default {
  mixins: [validationMixin],
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    variacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSelecaoComprador: false,
      quantidade: null,
      comprador: null,
      loadingCotacao: false,
      cotacaoEnviada: false,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    quantidadeMinima() {
      return `* Mínimo ${this.variacao.quantidade} ${this.oferta.unidadeMedida.descricao}.`;
    },
  },
  methods: {
    onInvalidDialog() {
      this.validateForm();
    },
    buildPayload() {
      return {
        company: this.comprador,
        ofertas: [
          {
            oferta: {
              id: this.oferta.id,
            },
            quantidade: this.quantidade,
          },
        ],
      };
    },
    onClickSolicitarCotacao() {
      this.loadingCotacao = true;
      OfertaCotacaoService.solicitarCotacao(this.buildPayload())
        .then(() => {
          this.$toast('Sua cotação foi enviada. Em breve entraremos em contato.', { color: 'success' });
          this.cotacaoEnviada = true;
        })
        .finally(() => {
          this.loadingCotacao = false;
        });
    },
    getComprador() {
      CompradorService.getComprador()
        .then(({ data }) => {
          [this.comprador] = data;
          this.showSelecaoComprador = data.length > 1;
        });
    },
  },
  created() {
    if (this.isLogged) {
      this.getComprador();
    }
  },
  validations: {
    quantidade: {
      required,
    },
    comprador: {
      required,
    },
  },
  components: {
    pxSolicitarCotacaoDialog: SolicitarCotacaoDialog,
  },
};
</script>
