<!--todo: implementar-->
<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    no-filter
    hide-selected
    hide-no-data
    item-text="nome"
    label="Empresa"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @blur="onBlur"
    v-bind="$attrs"
  />
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import UserGrupoService from '@/api/user-grupo-service';

const DEFAULT_LIMIT = 30;
const DEFAULT_OFFSET = 0;

export default {
  props: {
    value: Object,
    entidadeGrupoId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
  },
  methods: {
    seachCompradores(val) {
      const isSelected = this.localValue && this.localValue.pessoa.nome === val;

      const searchValue = isSelected || !val ? '' : val;

      this.loading = true;
      UserGrupoService.getUsersEntidade(this.entidadeGrupoId, {
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        query: `nome like "%${searchValue.toLowerCase()}%" order by nome`,
      })
        .then((response) => {
          this.items = response.data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
  },
  created() {
    this.debounceSearch = debounce(this.seachCompradores, 1000);
    if (this.value) {
      this.items = [this.value];
    }
  },
};
</script>
