<template>
  <v-alert
    dense
    outlined
    class="rounded-card-small body-2"
    color="success">
    <div>
      <v-icon color="success" left>mdi-whatsapp</v-icon>
      {{ normalText }}
      <a @click="onClickWhatsBtn">
        {{ actionText }}
      </a>
    </div>
  </v-alert>
</template>

<script>
const RD_WHATSAPP_BTN = 'rd-floating_button-l08303ns';

export default {
  props: {
    normalText: {
      type: String,
      default: 'Dúvida sobre este produto?',
    },
    actionText: {
      type: String,
      default: 'Fale com um especialista!',
    },
    text: {
      type: String,
      default: 'Olá, tenho uma dúvida sobre o marketplace Proxpect!',
    },
    phoneNumber: {
      type: String,
      default: '5548992120020',
    },
  },
  computed: {
    encodedText() {
      return encodeURI(this.text);
    },
    link() {
      return `https://api.whatsapp.com/send?phone=${this.phoneNumber}&text=${this.encodedText}`;
    },
  },
  methods: {
    onClickWhatsBtn() {
      const btn = document.getElementById(RD_WHATSAPP_BTN);
      if (!btn) {
        window.open(this.link, '_blank').focus();
        return;
      }

      btn.click();
    },
  },
};
</script>
