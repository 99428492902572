<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :max-width="cardWidth"
          :min-width="cardWidth"
          align="start"
          link
          class="fill-height rounded-card card-oferta"
          :target="target"
          :to="`/oferta/${oferta.id}`">
    <px-oferta-card-options v-if="oferta.disponivel && oferta.ativo"
                            v-show="hover && showOptions" :oferta="oferta"/>
    <px-oferta-foto :max-height="imageSize"
                    :min-height="imageSize"
                    :max-width="imageSize"
                    img-class="mt-5 mb-3"
                    align="center"
                    :alt="oferta.titulo"
                    :foto="oferta.foto"/>
    <px-selo-mais-por-menos v-if="hasDescontoProgressivo"
                            class="selo-inicial"/>
    <px-selo-desconto v-if="hasPromocao"
                      :class="seloPromocaoClass"
                      :oferta="oferta"/>
    <v-card-title class="oferta-title dense-title font-weight-medium d-flex align-start">
        <span :title="oferta.titulo">
          {{ oferta.titulo | truncate(70) }}
        </span>
    </v-card-title>
    <v-card-text v-if="oferta.disponivel && oferta.ativo">
      <template v-if="showPrice">
        <px-oferta-card-preco :oferta="oferta"/>
        <px-oferta-card-fornecedor v-if="showFornecedor && isLogged"
                                   class="mt-2"
                                   :oferta="oferta"/>
      </template>
    </v-card-text>
    <v-card-text v-else class="oferta-nao-disponivel-container">
      <v-icon class="mr-2" color="error">mdi-alert-outline</v-icon>
      Não disponível
    </v-card-text>
  </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import gridMixin from '@/mixins/grid-mixin';
import OfertaCardPreco from './OfertaCardPreco.vue';
import OfertaCardFornecedor from './OfertaCardFornecedor.vue';
import OfertaCardOptions from './OfertaCardOptions.vue';

export default {
  mixins: [gridMixin],
  props: {
    oferta: [Object],
    showOptions: {
      type: Boolean,
      default: true,
    },
    showFornecedor: {
      type: Boolean,
      default: true,
    },
    showDescontaos: {
      type: Boolean,
      default: true,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    openNewTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isLogged']),
    imageSize() {
      if (this.isMobile) {
        return '110';
      }
      return '200';
    },
    cardWidth() {
      if (this.isMobile) {
        return '160';
      }
      return '280';
    },
    target() {
      return this.openNewTab ? '_blank' : '_self';
    },
    hasDescontoProgressivo() {
      return this.showDescontaos && this.oferta.possuiDescontos;
    },
    hasPromocao() {
      return this.oferta.promocaoAtiva;
    },
    seloPromocaoClass() {
      return this.hasDescontoProgressivo ? 'selo-secundario' : 'selo-inicial';
    },
  },
  components: {
    pxOfertaCardPreco: OfertaCardPreco,
    pxOfertaCardFornecedor: OfertaCardFornecedor,
    pxOfertaCardOptions: OfertaCardOptions,
  },
};
</script>

<style scoped>
.oferta-title {
  min-height: 90px;
  word-break: break-word;
}

.oferta-nao-disponivel-container {
  min-height: 92px;
}

.card-oferta {
  box-shadow: 1px 3px 12px #00000029;
}

.card-oferta:hover {
  box-shadow: 3px 5px 20px #00000029;
}

.selo-inicial {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 1;
}

.selo-secundario {
  position: absolute;
  top: 110px;
  right: 0;
  z-index: 1;
}
</style>
