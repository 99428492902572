<template>
  <v-app>
    <router-view name="header"></router-view>
<!--    style="background-color: #ebedf0"-->
    <v-content :class="contentClass">
      <router-view></router-view>
    </v-content>
    <px-compare-component/>
    <router-view name="footer"></router-view>
  </v-app>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import PxCompareComponent from './modules/compare/PxCompareComponent.vue';

export default {
  mixins: [gridMixin],
  name: 'App',
  computed: {
    contentClass() {
      if (this.isMobile) {
        return '';
      }
      return 'content-container';
    },
  },
  components: {
    PxCompareComponent,
  },
};
</script>

<style scoped>
.content-container {
  margin-bottom: 100px;
}
</style>
