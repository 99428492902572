<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="450"
    max-width="600"
    content-class="card-shadow"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon
             :small="small"
             :class="btnClass"
             v-on="on"
             @click.stop.prevent=""
      >
        <v-icon :small="small">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="text--primary">
        <v-row class="title">
          <v-col cols="12">
            {{ fornecedor.pessoa.nome }}
            <px-open-pagina-fornecedor :id="fornecedor.id"/>
          </v-col>
        </v-row>
        <div v-if="showScore">
          <v-skeleton-loader
            :loading="loading"
            max-width="150"
            type="text"
          >
            <px-show-avaliacao-icon-simple span-class="text--primary"
                                           :avaliacao="pontuacao"/>
            <v-icon class="ml-3 mr-1 mb-1">mdi-calculator</v-icon>
            <px-fornecedor-score :score="score"/>
          </v-skeleton-loader>
        </div>
        <v-row class="">
          <v-col cols="12">
            <v-icon>mdi-domain</v-icon>
            {{ fornecedor.pessoa.cnpj | cnpj }}
            <px-regime-tributario :regime-tributario="fornecedor.pessoa.regimeTributario"/>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" sm="5" v-if="fornecedor.pessoa.telefone">
            <v-icon>mdi-phone</v-icon>
            {{ fornecedor.pessoa.telefone | phone }}
          </v-col>
          <v-col cols="12" sm="7" v-if="fornecedor.pessoa.email">
            <v-icon>mdi-email</v-icon>
            {{ email }}
          </v-col>
        </v-row>
        <v-row v-if="fornecedor.site">
          <v-col cols="12">
            <v-icon>mdi-web</v-icon>
            {{ fornecedor.site }}
            <v-btn icon :href="site" target="_blank">
              <v-icon small>
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12">
            <v-icon>mdi-map-marker</v-icon>
            {{ fornecedor.endereco | endereco }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import FornecedorService from '@/api/fornecedor-service';

export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-chevron-down',
    },
    btnClass: {
      type: String,
      default: '',
    },
    showScore: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    fornecedor: Object,
  },
  data: () => ({
    menuOpened: false,
    loading: false,
    score: null,
  }),
  computed: {
    site() {
      if (!this.fornecedor.site) {
        return null;
      }
      if (this.fornecedor.site.includes('http://') || this.fornecedor.site.includes('https://')) {
        return this.fornecedor.site;
      }
      return `https://${this.fornecedor.site}`;
    },
    email() {
      return this.fornecedor.pessoa.email;
    },
    pontuacao() {
      if (!this.score) {
        return null;
      }
      return this.score.pontuacaoMedia;
    },
  },
  methods: {
    getScore() {
      this.loading = true;
      FornecedorService.getFornecedorScore(this.fornecedor.id)
        .then(({ data }) => {
          this.score = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    menuOpened(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (this.menuOpened && this.showScore && !this.score) {
        this.getScore();
      }
    },
  },
};
</script>
