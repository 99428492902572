<template>
  <div v-if="showDetails"
       class="body-1 text--details--color">
    <px-oferta-imposto-nao-incluso :oferta="oferta" :variacao="variacao"/>
  </div>
</template>

<script>
import PxOfertaImpostoNaoIncluso from './PxOfertaImpostoNaoIncluso.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    variacao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showDetails() {
      return this.oferta.ipi && this.oferta.ipi.destacar;
    },
  },
  components: {
    PxOfertaImpostoNaoIncluso,
  },
};
</script>
