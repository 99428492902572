<template>
  <v-menu
    content-class="card-shadow"
    open-on-hover
    :close-on-content-click="false"
    v-model="menuOpened"
    max-width="380"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon
             small
             color="#EBBF0F"
             v-on="on"
      >
        <v-icon small>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>IPI não incluso</v-card-title>
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            Ao adicionar no carrinho, o valor do IPI será incluso no preço deste produto.
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  Preço final
                </v-list-item-content>
                <v-list-item-content class="justify-end font-weight-bold text--primary--color">
                  {{ precoTotal | currency }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <span>
                    Valor de IPI <span class="font-weight-bold">não incluso</span>
                  </span>
                </v-list-item-content>
                <v-list-item-content class="justify-end font-weight-bold">
                  {{ impostoSobrePrecoAVista | currency }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import currency from 'currency.js';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    variacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menuOpened: false,
    };
  },
  computed: {
    precoAVista() {
      return this.oferta.promocaoAtiva
        ? this.variacao.precoPromocionalAVista : this.variacao.precoAVista;
    },
    precoTotal() {
      return currency(this.precoAVista)
        .add(this.impostoSobrePrecoAVista)
        .value;
    },
    ipiEntity() {
      return this.variacao.ipi ? this.variacao.ipi : this.variacao;
    },
    impostoSobrePrecoAVista() {
      const ipi = this.ipiEntity;
      return ipi.impostoSobrePrecoAVista;
    },
  },
};
</script>
