<template>
  <div>
    <px-header-default v-if="!isMobile"/>
    <px-header-mobile v-else/>
  </div>

</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HeaderDefault from './HeaderDefault.vue';
import HeaderMobile from './HeaderMobile.vue';

export default {
  mixins: [gridMixin],
  components: {
    pxHeaderDefault: HeaderDefault,
    pxHeaderMobile: HeaderMobile,
  },
};
</script>
