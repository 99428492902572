var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-slide-x-transition',[_c('div',{staticClass:"card-options"},[(_vm.showCompare)?_c('div',[_c('px-oferta-comparacao-select',{attrs:{"oferta-param":_vm.oferta},scopedSlots:_vm._u([{key:"add",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","elevation":"1","color":"primary"},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, onTooltip, on)),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar à comparação")])])]}},{key:"remove",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","elevation":"1","color":"primary"},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, onTooltip, on)),[_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Remover da comparação")])])]}}],null,false,1550301164)})],1):_vm._e(),(_vm.showCart)?_c('div',{staticClass:"mt-2"},[_c('px-oferta-dialog',{attrs:{"id-oferta":_vm.oferta.id},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","title":"Adicionar ao carrinho","elevation":"1","color":"secondary"},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, onTooltip, on)),[_c('v-icon',[_vm._v("mdi-cart")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar ao carrinho")])])]}}],null,false,272841814)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }