<template>
  <div>
    <px-default-footer v-if="!isMobile"/>
    <px-mobile-footer v-else/>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import DefaultFooter from './DefaultFooter.vue';
import MobileFooter from './MobileFooter.vue';

export default {
  mixins: [gridMixin],
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),
  components: {
    pxDefaultFooter: DefaultFooter,
    pxMobileFooter: MobileFooter,
  },
};
</script>
