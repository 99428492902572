<template>
  <div class="mt-5">
    <template v-if="oferta.descontos && oferta.descontos.length">
      <div class="d-flex mais-por-menos pt-1 pb-1 pl-3 white--text body-1 mb-3">
        <div><b>leve +</b> por -</div>
      </div>
      <div v-for="(faixa, index) in faixasCollapse"
          :key="index" class="font-weight-bold body-1">
        <span>
          + de {{ faixa.quantidade }} {{ oferta.unidadeMedida.descricao }}:
        </span>
        <span class="text--primary--color">
          {{ faixa.precoAVista | currency }}
        </span>
        &nbsp;à vista&nbsp;
        <span class="text--primary--color">{{ faixa.precoAPrazo | currency }}</span>
        &nbsp;a prazo&nbsp;
      </div>
      <div v-if="hasMoreFaixas">
        <v-btn color="primary" text x-small @click="onShowMore">
          {{ btnLabel }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import currency from 'currency.js';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    quantidade: {
      type: Number,
    },
    variacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
      faixasPreco: [],
    };
  },
  computed: {
    btnLabel() {
      return this.showMore ? 'Ver menos' : 'Ver mais';
    },
    hasMoreFaixas() {
      return this.faixasPreco.length > 3;
    },
    faixasCollapse() {
      if (this.showMore || !this.hasMoreFaixas) {
        return this.faixasPreco;
      }
      return this.faixasPreco.slice(0, 3);
    },
  },
  methods: {
    onShowMore() {
      this.showMore = !this.showMore;
    },
    getPrecoPromocaoAVistaOu(precoAVista) {
      if (this.oferta.promocaoAtiva && this.variacao.precoPromocionalAVista < precoAVista) {
        return this.variacao.precoPromocionalAVista;
      }
      return precoAVista;
    },
    getPrecoPromocaoAPrazoOu(preco) {
      if (this.oferta.promocaoAtiva && this.variacao.precoPromocionalAPrazo < preco) {
        return this.variacao.precoPromocionalAPrazo;
      }
      return preco;
    },
    calculateDesconto(preco, desconto) {
      const percetualDesconto = currency(1, { precision: 4 })
        .subtract(currency(desconto, { precision: 4 }));
      return currency(preco, { precision: 4 })
        .multiply(percetualDesconto)
        .value;
    },
    buildFaixas() {
      const faixas = [];

      if (this.oferta.descontos) {
        this.oferta.descontos.forEach((desconto) => {
          faixas.push({
            quantidade: desconto.quantidade,
            precoAPrazo: this.getPrecoPromocaoAPrazoOu(
              this.calculateDesconto(this.variacao.precoAPrazo, desconto.percentualDesconto),
            ),
            precoAVista: this.getPrecoPromocaoAVistaOu(
              this.calculateDesconto(this.variacao.precoAVista, desconto.percentualDesconto),
            ),
            enabled: false,
          });
        });
      }

      this.faixasPreco = faixas.map((faixa, index) => {
        const next = faixas[index + 1];
        return { ...faixa, nextQuantidade: next ? next.quantidade : null };
      });
    },
  },
  watch: {
    variacao(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.buildFaixas();
    },
  },
  created() {
    this.buildFaixas();
  },
};
</script>

<style scoped>
.mais-por-menos {
  max-width: 130px;
  background: transparent
  linear-gradient(268deg, #3466B9 0%, #0088FF 100%) 0 0 no-repeat padding-box;
  border-radius: 10px;
}
</style>
